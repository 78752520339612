$horizontal-padding: 32px;
$vertical-padding: 32px;
$input-border-radius: 5px;
$input-horizontal-padding: 24px;
$input-vertical-padding: 13px;
$button-border-radius: 5px;

:root {
    --horizontal-padding: #{$horizontal-padding};
    --vertical-padding: #{$vertical-padding};
    --input-border-radius: #{$input-border-radius};
    --input-horizontal-padding: #{$input-horizontal-padding};
    --input-vertical-padding: #{$input-vertical-padding};
    --button-border-radius: #{$button-border-radius};
}
