.button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  appearance: none;
  background-color: var(--color-main);
  border-radius: var(--button-border-radius);
  color: #fff;
  border: none;
  font-size: 0.9rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;

  padding: 1rem 2.5rem;
  transition: background-color 0.25s;

  &:hover {
    background-color: var(--color-main-light) !important;
  }
}

.hasIcon {
  position: relative;

  span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: absolute;
      height: auto;
      width: 1.5rem;
    }
  }
}

.hasText {
  span {
    margin-left: 1.5rem;
    svg {
      left: -2.25rem;
    }
  }
}

.hasText:not(.hasIcon) {
  span {
    margin-left: 0;
  }
}

.button:disabled {
  cursor: not-allowed;
  background-color: var(--color-main-light);
  color: rgba(255, 255, 255, 0.75);
}

.loading {
  cursor: default !important;
  opacity: 1 !important;
  background-color: var(--color-main-light) !important;
  color: var(--color-main-light) !important;

  svg {
    fill: var(--color-main-light) !important;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > * {
    height: 100%;
  }
}
