.wrapper {
  width: 100%;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem 1.5rem;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: var(--input-border-radius);
}

.title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.headerOpen {
  background-color: #f1f1f1;
}

.hasArrow {
  padding-right: 3rem;
}

.contentWrapper {
  max-height: 0;
  overflow: hidden;
}

.open {
  max-height: inherit;
}

.arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);

  svg {
    transform: scaleY(1);
    transform-origin: center;
    width: 28px;
    height: 28px;
    transition: transform 0.15s linear;
  }
}

.arrowUp {
  svg {
    transform: scaleY(-1);
  }
}

.indicator {
  height: 12px;
  width: 12px;
  min-width: 12px;
  border-radius: 50%;
  margin-left: 1.5rem;
  background-color: grey;
}

.green {
  background-color: var(--color-green);
}
.yellow {
  background-color: var(--color-yellow);
}
.red {
  background-color: var(--color-red);
}
.undefined {
  background-color: grey;
}
