$color-main: #7367f0;
$color-text: #303030;
$color-btn: #7367f0;

$color-box-text: #fff;
$color-box-grey: #303030;
$color-box-green: #4eb769;
$color-box-red: #d40035;

$color-darkred: #a5081e;
$color-red: #d40035;
$color-yellow: #da9e45;
$color-green: #4eb769;

:root {
    --color-main: #{$color-main};
    --color-main-light: #{lighten($color-main, 8%)};
    --color-main-dark: #{darken($color-main, 7%)};

    --color-text: #{$color-text};

    --color-btn: #{$color-btn};
    --color-btn-light: #{lighten($color-btn, 8%)};
    --color-btn-dark: #{darken($color-btn, 7%)};

    --color-box-text: #{$color-box-text};
    --color-box-grey: #{$color-box-grey};
    --color-box-green: #{$color-box-green};
    --color-box-red: #{$color-box-red};

    --color-darkred: #{$color-darkred};
    --color-red: #{$color-red};
    --color-yellow: #{$color-yellow};
    --color-green: #{$color-green};
}
