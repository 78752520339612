.wrapper {
  display: flex;
  margin: 1.5rem auto 3rem;

  button {
    margin-left: 1rem;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.buttons {
  position: relative;
  display: flex;

  a {
    text-decoration: none;
  }

  @media (max-width: 650px) {
    margin-top: 1rem;
    justify-content: space-between;

    button {
      margin-left: 0;
    }

    & > *:not(:first-child) {
      margin-left: 1rem;
    }

    a {
      flex: 1;

      button {
        width: 100%;
      }
    }
  }
}

.fallback {
  position: absolute;
  background: #fff;
  right: 0;
  margin-left: 0 !important;
  bottom: calc(100% + 7px);
  border: 1px solid #e2e2e2;
  border-radius: var(--button-border-radius);

  transform-origin: center;
  animation: popUp 0.25s cubic-bezier(0.42, 0.38, 0.43, 1.7);

  @media (max-width: 650px) {
    bottom: 100%;
    left: 0;
    margin-bottom: 1rem;
  }
}

.socialIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  @media (max-width: 650px) {
    justify-content: space-between;
    padding: 0 8%;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;

    a,
    span {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem 0.9rem;
      border-radius: var(--button-border-radius);

      &:hover {
        background: #f5f5f5;
      }

      svg {
        fill: var(--color-main);
        width: 30px;
        height: 30px;
      }
    }
  }
}

@keyframes popUp {
  from {
    opacity: 0;
    transform: scale(0.5) translate3d(0, 0, 0);
  }
  to {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
