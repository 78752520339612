.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: inline-block;
    background-color: #fff;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    animation: bounce 1.2s 0s ease infinite;
    margin: 0 0.15rem !important;

    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
  }
}

.secondary {
  composes: loader;

  span {
    background-color: var(--color-main);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(50%);
  }
  60% {
    transform: translateY(-50%);
  }
  80% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(50%);
  }
  60% {
    transform: translateY(-50%);
  }
  80% {
    transform: translateY(0);
  }
}
