.backbtn {
    appearance: none;
    border: none;
    outline: none;
    background: none;

    cursor: pointer;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color-main);
    font-size: 1rem;
    font-weight: 500;

    opacity: 1;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.75;
    }

    svg {
        height: auto;
        width: 2rem;
        fill: var(--color-main);
        margin: auto -2px auto -10px;
    }
}
