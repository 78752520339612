.wrapper {
  max-width: 55rem;
  margin: 0 auto;
  padding: var(--vertical-padding) var(--horizontal-padding);

  // @media (max-width: 500px) {
  //   padding: var(--vertical-padding) var(--horizontal-padding);
  //   padding: calc(var(--vertical-padding) / 2) calc(var(--horizontal-padding) / 2);
  // }
}
