body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.35;
    color: var(--color-text);
}

*,
*::before,
*::after {
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
    color: var(--color-text);
}

h1 {
    font-weight: 600;
    margin-bottom: 1.025rem;
}

h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h2 {
    margin-bottom: 0.75rem;
}

h3 {
    margin-bottom: 0.35rem;
}

p {
    font-size: 1rem;
    line-height: 1.5;
}

a {
    font-weight: 500;
    text-decoration: none;
    color: var(--color-main);

    @media (hover: hover) {
        &:hover {
            color: var(--color-main-dark);
            text-decoration: underline;
        }
    }
}
