.icon {
  cursor: pointer;

  svg {
    fill: #bbb;
  }
}

.loading {
  svg {
    animation: blinking 0.75s linear infinite alternate;
  }
}

@keyframes blinking {
  from {
    fill: #bbb;
  }
  to {
    fill: var(--color-main);
  }
}
