.wrapper {
    label {
        display: inline-block;
        position: relative;
        cursor: pointer;
        width: 2.5rem;
        height: 2.5rem;
    }
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        fill: #ccc;
    }
    input {
        display: none;
    }
}

.active {
    svg {
        fill: var(--color-main);
    }
}

.thanks {
    display: block;
    margin-top: 0.475rem;
    animation: fadeIn 1s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
