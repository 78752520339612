@import "../../../assets/styles/colors";

.datepicker {
    display: flex;
    align-items: center;
    width: 100%;

    .DateRangePicker_picker {
        width: 100%;
    }

    .DateRangePicker {
        width: 100%;
    }

    .DateRangePicker > div {
        display: flex;
        align-items: center;
    }

    .DateRangePickerInput {
        flex: 1 0 auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e2e2e2;
        border-radius: var(--input-border-radius);
        overflow: hidden;
    }

    .DateInput {
        flex: 1 0 100px;
        width: inherit;
    }

    input {
        cursor: pointer;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        line-height: inherit;
        padding: var(--input-vertical-padding) 0;
        color: var(--color-text);
        border: 0;
    }

    &.active {
        .DateRangePickerInput {
            border-color: var(--color-main);
        }
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
        background: var(--color-main);
        border: 1px double var(--color-main);
    }

    .CalendarDay__hovered_span {
        background: var(--color-main-light);
        border: 1px double var(--color-main-light);
        color: #fff;
    }

    .CalendarDay__hovered_span:hover {
        background: var(--color-main);
        border: 1px double var(--color-main);
    }

    .CalendarDay__selected_span {
        background: var(--color-main-light);
        border: 1px double var(--color-main-light);
    }

    .DayPickerNavigation_button__verticalDefault {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        border: none;
        border-top: 1px solid #e2e2e2;

        .DayPickerNavigation_svg__vertical {
            width: 20px;
            height: 20px;
        }
    }
}
