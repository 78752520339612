.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: var(--button-border-radius);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 2rem;
}

.tab {
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--button-border-radius);
    text-align: center;
    padding: 0.75rem 1rem;

    svg {
        font-size: 17px;
        margin-right: 0.325rem;
    }
}

.active {
    color: #fff;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    background-color: var(--color-main);
}
