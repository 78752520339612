.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > *:last-child {
        margin-bottom: 0;
    }
}

.inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;

    & > *:last-child {
        margin-bottom: 0;
    }
}

.options {
    list-style: none;
    border: 1px solid #e2e2e2;
    border-top: none;
    border-radius: 5px;

    li {
        cursor: pointer;
        padding: 0.625rem 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background-color: #f1f1f1;
        }

        small {
            margin-left: 0.5rem;
            opacity: 0.5;
        }
    }
}
