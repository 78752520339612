.locationinputs {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  & > div {
    flex: 1 0 auto;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
}

.singleinput {
  input {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  & > svg {
    position: absolute;
    left: 0.75rem;
    transition: none;
    fill: #444;
  }

  input:focus + svg {
    fill: var(--color-main);
  }
}

.options {
  overflow: hidden;
  position: absolute;
  background: #fff;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  border: 1px solid #e2e2e2;
  border-top: none;
  border-radius: 5px;

  li {
    cursor: pointer;
    padding: 0.625rem 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: #f1f1f1;
    }

    small {
      margin-left: 0.5rem;
      opacity: 0.5;
    }
  }
}

.geolocation {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  margin: 0 0.75rem;

  svg {
    width: 20px;
    height: 20px;
  }
}
