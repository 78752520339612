.wrapper {
    display: flex;
    margin: 1.5rem auto 3rem;

    button {
        margin-left: 1rem;
    }

    @media (max-width: 550px) {
        flex-direction: column;

        button {
            margin-left: 0;
            margin-top: 1rem;
        }
    }
}
