.box {
  padding: 1.5rem 2rem;
  border-radius: 5px;
  background-color: var(--color-box-grey);
  text-align: center;
  width: 100%;
  margin-bottom: 1.5rem;
  color: var(--color-box-text);

  a {
    color: var(--color-box-text);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px solid var(--color-box-text);
    opacity: 1;

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.75;
    }
  }
}

.error {
  composes: box;
  background-color: var(--color-box-red);
}

.success {
  composes: box;
  background-color: var(--color-box-green);
}
