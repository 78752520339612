.wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0.5rem 0 0.5rem;
}

.until100,
.above100 {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    height: 30px;
}

.until100 {
    flex: 0 0 75%;
    margin-right: 0.5rem;
}

.bar {
    cursor: default;
    flex: 1 0 auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    color: #fff;
    font-size: 0.65rem;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 500px) {
        font-size: 0.525rem;
    }

    &:hover {
        opacity: 1;
    }

    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.active {
    opacity: 1;
}

.green {
    background-color: var(--color-green);
    flex: 0 0 35%;
}
.yellow {
    background-color: var(--color-yellow);
    flex: 0 0 15%;
}
.red {
    background-color: var(--color-red);
    flex: 0 0 50%;
}

.darkred {
    background-color: var(--color-darkred);
}

.indicator {
    position: absolute;
    left: 0;
    height: 100%;
    width: 2px;
    margin-left: -1px;
    background-color: #fff;
}

.countyTo,
.countyFrom {
    position: absolute;
    transform: translateX(-50%);
    font-size: 0.6rem;
    white-space: nowrap;
}

.countyFrom {
    top: -15px;
}
.countyTo {
    bottom: -15px;
}
