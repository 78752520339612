.loader {
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 5px;
}

.wrapper {
  max-width: 100%;
  margin: 1rem auto 2rem;
}

.infoContent {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding-left: 2rem;
  border-left: 1px solid #eee;

  @media (max-width: 500px) {
    margin-left: 0rem;
    padding-left: 1.5rem;
  }
}

.state,
.tourismInfo,
.overview,
.restrictions {
  margin-bottom: 0.75rem;
}

.tourismInfo {
  &:last-child {
    margin-bottom: 0;
  }
}

.overviewContent,
.restrictionsContent {
  padding: 0.75rem 0.35rem;
  margin-bottom: 1.5rem;
}

.tourismInfoContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  margin-top: 1rem;
  gap: 1rem 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }

  & > div {
    position: relative;
    background-color: #f7f7f7;
    padding: 1.05rem 1.25rem;
    border-radius: 5px;
  }
}

.tourismInfoTitle {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.7rem;
  width: 100%;
  padding-right: 2.5rem;
}

.indicator {
  position: absolute;
  top: 1.3rem;
  right: 1.55rem;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: grey;
}

.green {
  background-color: var(--color-green);
}
.yellow {
  background-color: var(--color-yellow);
}
.red {
  background-color: var(--color-red);
}
.undefined {
  background-color: grey;
}

.additionalInfo {
  padding: 0 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.additionalTitle {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
