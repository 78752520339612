.box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    border-radius: 5px;
    color: #fff;
    align-self: stretch;
    word-break: break-word;
    height: 100%;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.table {
    font-size: 0.825rem;
    width: 100%;
    margin: 0 auto;

    th,
    td {
        padding: 0.125rem 0;
    }
    th {
        text-align: left;
        min-width: 7rem;
    }
    td {
        text-align: right;
    }
}
