.button {
  appearance: none;
  outline: none;
  border: none;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 1.75rem;
  padding: 0.2rem;
  margin: 0 0.75rem;
  z-index: 1;

  background-color: #f2f2f2;
  border: none;

  border-radius: 5px;

  @media (max-width: 700px) {
    margin: -0.5rem 2.15rem;
    align-self: flex-end;
    transform: rotate(90deg);
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #404040;
    transition: 0s;
  }

  &:hover {
    background-color: var(--color-main);

    svg {
      fill: #fff;
    }
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      opacity: 0.5;
    }
  }
}
