@import "../../assets/styles/mixins";

.inputwrapper {
    position: relative;
    display: flex;
    align-items: center;

    label {
        display: none;
    }
}

.error {
    input {
        border-color: var(--color-box-red) !important;
        box-shadow: inset 0 0 0 1px var(--color-box-red) !important;
    }

    svg {
        fill: var(--color-box-red) !important;
    }
}

.input {
    padding: 0.8rem 1.5rem;
    padding: var(--input-vertical-padding) var(--input-horizontal-padding);
    font-size: 1.1rem;
    appearance: none;
    border: 1px solid #e2e2e2;
    border-radius: var(--input-border-radius);
    width: 100%;
    text-overflow: ellipsis;

    &:focus {
        outline: none;
        border-color: var(--color-main);
        box-shadow: inset 0 0 0 1px var(--color-main);
    }

    @include placeholder {
        opacity: 0.5;
    }
}

.hasIcon {
    input {
        padding-left: 3.25rem !important;
    }

    svg {
        position: absolute;
        left: 1rem;
        transition: none;
        fill: #aaa;
    }

    input:focus + svg {
        fill: var(--color-main);
    }
}
