.infotext {
    text-align: center;
    max-width: 45rem;
    margin: 1rem auto 3rem;
}

.feedback {
    text-align: center;
}

.reset {
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;
}
