.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem auto 1rem;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.result {
  flex: 1;
  width: 100%;
}

.arrowTo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  width: 3rem;
  height: 3rem;

  @media (max-width: 700px) {
    align-self: center;
    transform: rotate(90deg);
    margin: 1.5rem;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #ccc;
  }
}

.copyright {
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  opacity: 0.5;
  display: inline-block;
  margin-bottom: 2rem;

  a {
    color: inherit;
  }
}

.inlineButton {
  cursor: pointer;
  color: var(--color-main);
  font-weight: 600;
}

.instructions {
  padding: 1.25rem 2rem;
  background-color: #f7f7f7;
  border-radius: 5px;
}

.inlineIcon {
  width: 20px;
  height: 16px;
  display: inline-block;

  svg {
    position: absolute;
    margin-top: 0px;
    width: 20px;
    height: 20px;
    opacity: 0.95;
  }
}

.rotatedIcon {
  svg {
    transform: rotate(-90deg);
    transform-origin: center;
  }
}
