.wrapper {
    margin-bottom: 1.75rem;
}

.title {
    margin-bottom: 0.5rem;
}

.info {
    margin-bottom: 1.25rem;
    padding: 1.65rem 2rem;
    background-color: #f5f5f5;
    border-radius: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}

.infolocation {
    font-size: 1rem;
    margin-bottom: 0.4rem;
}

.singleinfo {
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}
